import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import InHouseForm from "../components/InHouseForm";

import Layout from "../components/layout";
import { inHouseForms } from "../config/inHouseForms";
import { getTitle } from "../helpers/globalHelpers";

const EditForm = () => {
  const [formId, setFormId] = useState();
  const [formType, setFormType] = useState();
  const [submissionId, setSubmissionId] = useState();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setFormId(urlParams.get("formId"));
    setFormType(urlParams.get("formType"));
    setSubmissionId(urlParams.get("submissionId"));
  }, []);

  return (
    <Layout pageName="Edit Form">
      <p class="title">{getTitle(formType)}</p>
      <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link
              class="fpBreadcrumbLink"
              to={`/selectForm?formType=${formType}`}
            >
              Select a form
            </Link>
          </li>
          <li>
            <p class="fpMiddleBreadcrumb">Edit submission</p>
          </li>
          <li>
            <p class="fpEndBreadcrumb">{`ID: ${submissionId}`}</p>
          </li>
        </ul>
      </nav>
      <InHouseForm config={inHouseForms[formId]} submissionId={submissionId} />
    </Layout>
  );
};

export default EditForm;
